import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import moment from "moment";

navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

const SpyCam = forwardRef(({ onChange }, ref) => {
    const video = useRef();
    const canvas = useRef();
    const [stream, setStream] = useState(null);

    const startStreaming = () => {
        if (null == stream) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (mediaStream) {
                    setStream(mediaStream);
                    video.current.srcObject = mediaStream;
                    video.current.play();
                })
                .catch(function (err) {
                    console.log("Unable to access camera: " + err);
                });
        }
    }

    useEffect(() => {
        startStreaming();
        return stopStreaming()
    }, []);

    const stopStreaming = () => {
        if (null != stream) {
            var track = stream.getTracks()[0];
            track.stop();
            video.current.load();
        }
    }

    const captureSnapshot = async () => {
        if (null != stream) {
            var ctx = canvas.current.getContext('2d');
            ctx.drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height);
            const timeNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            ctx.strokeText(timeNow, 4, canvas.current.height-4);
            let src = canvas.current.toDataURL("image/png");
            onChange(dataURItoBlob(src));
            // setStream(null);
        }
    }

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var buffer = new ArrayBuffer(byteString.length);
        var data = new DataView(buffer);

        for (var i = 0; i < byteString.length; i++) {
            data.setUint8(i, byteString.charCodeAt(i));
        }

        return new Blob([buffer], { type: mimeString });
    }

    useImperativeHandle(ref, () => ({
        takeSpyImage() {
            captureSnapshot();
        },
    }));

    return <div className="flex flex-col justify-center items-center" style={{ display: 'none' }}>
        <video ref={video} width="320" height="240"></video>
        <canvas ref={canvas} width="320" height="240"></canvas>
    </div>
});

export default SpyCam;