import { useEffect, useRef, useState, useCallback } from "react";

navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);

const Camera = ({ previous = null, onChange }) => {
    const video = useRef();
    const canvas = useRef();
    const snapshot = useRef();
    const [image, setImage] = useState(null);
    const [stream, setStream] = useState(null);

    const startStreaming = useCallback(() => {
        if (null == stream) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (mediaStream) {
                    setStream(mediaStream);
                    video.current.srcObject = mediaStream;
                    video.current.play();
                })
                .catch(function (err) {
                    console.log("Unable to access camera: " + err);
                });
        }
    }, [stream]);

    const stopStreaming = useCallback(() => {
        if (null != stream) {
            var track = stream.getTracks()[0];
            track.stop();
            video.current.load();
        }
    }, [stream]);

    useEffect(() => {
        startStreaming();
    }, [startStreaming]);

    const captureSnapshot = () => {
        if (null != stream) {
            var ctx = canvas.current.getContext('2d');
            var img = new Image();
            ctx.drawImage(video.current, 0, 0, canvas.current.width, canvas.current.height);
            let src = canvas.current.toDataURL("image/png");
            setImage(src);
            img.src = src;
            img.width = 240;
            snapshot.current.innerHTML = '';
            snapshot.current.appendChild(img);
            stopStreaming();
            onChange(dataURItoBlob(src));
        }
    }

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var buffer = new ArrayBuffer(byteString.length);
        var data = new DataView(buffer);

        for (var i = 0; i < byteString.length; i++) {
            data.setUint8(i, byteString.charCodeAt(i));
        }

        return new Blob([buffer], { type: mimeString });
    }

    return <div className="flex flex-col justify-center items-center">
        <video ref={video} width="320" height="240" style={{ display: image ? 'none' : 'block' }}></video>
        <canvas ref={canvas} width="320" height="240" style={{ display: 'none' }}></canvas>
        <div ref={snapshot} style={{ display: image ? 'block' : 'none' }}></div>
        <div className="flex flex-row justify-between mt-2">
            <button type="button" onClick={() => captureSnapshot()} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2" style={{ opacity: image ? '0.5' : '1' }}>Capture Image</button>
            <button type="button" onClick={() => {
                setImage(null);
                setStream(null);
                startStreaming();
            }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded" style={{ opacity: image ? '1' : '0.5' }}>Start Camera</button>
        </div>
    </div>
};

export default Camera;