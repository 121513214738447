// import * as React from 'react';
// import {
//     AgoraVideoPlayer,
//     createMicrophoneAndCameraTracks,
// } from "agora-rtc-react";
// import http from "../../utils/http";

// const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

// const Videos = ({ role, users, tracks }) => {
//     return (
//         <React.Fragment>
//             <div className="assess-de h-2/5">
//                 <p>{role.name === 'candidate' ? 'Candidate' : 'Assessor'}</p>
//                 <div className="can-he" style={{ height: '100%', width: '100%' }}>
//                     <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} style={{ height: 'inherit', width: 'inherit' }} />
//                 </div>
//             </div>
//             <div className="assess-de h-2/5">
//                 <p>{role.name === 'candidate' ? 'Assessor' : 'Candidate'}</p>
//                 <div className="can-he" style={{ height: '100%', width: '100%' }}>
//                     {users.length > 0 &&
//                         [users[0]].map((user) => {
//                             if (user.videoTrack) {
//                                 return <AgoraVideoPlayer videoTrack={user.videoTrack} style={{ height: 'inherit', width: 'inherit' }} key={user.uid} />;
//                             } else return null;
//                         })}
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// };

// const VivaVideoCall = ({ role, channelName, client, config, onStarted , recordingEnable}) => {
//     const [users, setUsers] = React.useState([]);
//     const [start, setStart] = React.useState(false);
//     const { ready, tracks } = useMicrophoneAndCameraTracks();

//     const startRecording = React.useCallback((channel, token) => {
//         http.post(`/api/v1/agora/start-recording`, {
//             uid: 0,
//             token: token,
//             channel: channel
//         }).then((res) => {
//             console.log(res);
//         });
//     }, []);

//     const leaveChannel = React.useCallback(async () => {
//         if (tracks) {
//             await client.leave();
//             client.removeAllListeners();
//             if (tracks.length > 0) {
//                 tracks[0].close();
//             }
//             if (tracks.length > 1) {
//                 tracks[1].close();
//             }
//             setStart(false);
//         }
//     }, [tracks, client]);

//     React.useEffect(() => {
//         // function to initialise the SDK
//         let init = async (name) => {
//             client.on("user-published", async (user, mediaType) => {
//                 await client.subscribe(user, mediaType);
//                 if (mediaType === "video") {
//                     setUsers((prevUsers) => {
//                         return [...prevUsers, user];
//                     });
//                 }
//                 if (mediaType === "audio") {
//                     user.audioTrack?.play();
//                 }
//             });

//             client.on("user-unpublished", (user, type) => {
//                 if (type === "audio") {
//                     user.audioTrack?.stop();
//                 }
//                 if (type === "video") {
//                     setUsers((prevUsers) => {
//                         return prevUsers.filter((User) => User.uid !== user.uid);
//                     });
//                 }
//             });

//             client.on("user-left", (user) => {
//                 setUsers((prevUsers) => {
//                     return prevUsers.filter((User) => User.uid !== user.uid);
//                 });
//             });

//             await client.join(config.appId, name, config.token, null);
//             if (tracks) {
//                 await client.publish([tracks[0], tracks[1]]);
//                 if (role.name === 'assessor' && recordingEnable) {
//                     onStarted();
//                 }
//             };
//             setStart(true);
//         };

//         if (ready && tracks) {
//             init(channelName);
//         }
//     }, [startRecording, role, channelName, client, ready, tracks, config, recordingEnable, onStarted]);

//     React.useEffect(() => {
//         console.info('started');
//         return () => {
//             leaveChannel();
//         }
//     }, [leaveChannel]);

//     return (
//         <div className='col-span-2'>
//             {start && tracks && <Videos role={role} users={users} tracks={tracks} />}
//         </div>
//     );
// };

// export default VivaVideoCall;

import * as React from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { MdScreenShare } from "react-icons/md";
import { AiOutlineFullscreen } from "react-icons/ai";
import ScreenRecorder from '../../components/ScreenRecording'

const VivaVideoCall = (props) => {

    const { userId, channelName, onStarted, role, recordingEnable } = props

    const { recorder } = ScreenRecorder()

    const [stream, setStream] = React.useState();
    const [users, setUsers] = React.useState({});
    const [caller, setCaller] = React.useState("");
    const [yourID, setYourID] = React.useState("");
    const [callerSignal, setCallerSignal] = React.useState();
    const [receivingCall, setReceivingCall] = React.useState(false);
    const [callingFriend, setCallingFriend] = React.useState(false);
    const [callAccepted, setCallAccepted] = React.useState(false);
    const [callRejected, setCallRejected] = React.useState(false);
    const [candidateOffline, setCandidateOffline] = React.useState(false)
    const [isConnected, setIsConnected] = React.useState(false);

    const userVideo = React.useRef();
    const partnerVideo = React.useRef();
    const socket = React.useRef();
    const connectionRef = React.useRef();

    React.useEffect(() => {
        if (!(channelName in users)) {
            setCallAccepted(false)
        }
    }, [channelName, users])

    const endCall = React.useCallback(() => {
        connectionRef.current.destroy();
        setReceivingCall(false)
        setCallAccepted(false)
        setCallingFriend(false)
        setCallRejected(false)
    }, [])

    const callPeer = React.useCallback((id) => {
        if (id !== '' && users[id] && id !== yourID) {

            if (connectionRef.current) {
                connectionRef.current.destroy();
                socket.current.off('callAccepted');
            }

            setCaller(id)
            setCallingFriend(true)

            const peer = new Peer({
                initiator: true,
                trickle: false,
                config: {
                    iceServers: [
                        { url: 'stun:stun.l.google.com:19302' },
                        {
                            url: 'turn:numb.viagenie.ca',
                            credential: 'muazkh',
                            username: 'webrtc@live.com'
                        }
                    ]
                },
                stream: stream,
            });

            connectionRef.current = peer;

            peer.on("signal", data => {
                socket.current.emit("callUser", { userToCall: id, signalData: data, from: yourID })
            })

            peer.on("stream", stream => {
                if (partnerVideo.current) {
                    partnerVideo.current.srcObject = stream;
                }
            });

            peer.on('error', (err) => {
                console.log(err)
                endCall()
            })

            socket.current.on("callAccepted", signal => {
                setIsConnected(true);
                setCallAccepted(true);
                peer.signal(signal);
            })

            return "success"

        } else {
            setCandidateOffline(true)
        }
    }, [endCall, stream, users, yourID])

    const acceptCall = React.useCallback(() => {
        setCallAccepted(true);
        setIsConnected(true);

        const peer = new Peer({
            initiator: false,
            trickle: false,
            stream: stream,
        });

        connectionRef.current = peer

        peer.on("signal", data => {
            socket.current.emit("acceptCall", { signal: data, to: caller })
        })

        peer.on("stream", stream => {
            partnerVideo.current.srcObject = stream;
        });

        peer.on('error', (err) => {
            console.log(err)
            endCall()
        })

        peer.signal(callerSignal);

        // Check if the peer connection is established
        if (peer.connected) {
            console.log("Peer connection established");
        } else {
            console.log("Peer connection not yet established");
        }

    }, [caller, callerSignal, endCall, stream])

    function renderLanding() {
        if (!callRejected && !callAccepted && !callingFriend) {
            return 'block'
        }
        return 'none'
    }

    function renderCall() {
        if (!callRejected && !callAccepted && !callingFriend) {
            return 'none'
        }
        return 'block'
    }

    let incomingCall;
    if (receivingCall && !callAccepted && !callRejected) {
        incomingCall = (acceptCall())
    }

    const startStreaming = React.useCallback(() => {
        socket.current = io.connect(process.env.REACT_APP_API_URL);

        socket.current.on('connect', () => {
            console.log("Connected");
            setIsConnected(true);
        });

        socket.current.on('disconnect', () => {
            console.log("Disconnected");
            setIsConnected(false);
            setCallAccepted(false)
        });

        socket.current.on("yourID", (id) => {
            setYourID(id);
        })

        socket.current.on("UserLeft", (id) => {
            console.log("User Disconnect " + id)
            setIsConnected(false);
        })

        socket.current.on("allUsers", (users) => {
            setUsers(users);
        })

        socket.current.on("UserSignal", (data) => {
            setReceivingCall(true);
            setCaller(data.from);
            setCallerSignal(data.signal);
        })

        return () => {
            socket.current.disconnect();
            console.log('Socket disconnected');
            if (connectionRef.current) {
                connectionRef.current.destroy();
                setIsConnected(false);
                socket.current.off('callAccepted');
            }
        };
    }, [])

    const getUserMedia = React.useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setStream(stream);
            if (stream) {
                userVideo.current.srcObject = stream;
                if (userId) {
                    socket.current.emit("UsersID", { id: userId })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }, [userId])

    const shareScreen = () => {
        navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: {
                mediaSource: 'screen',
                cursor: 'always',
                displaySurface: 'monitor',
                frameRate: { ideal: 60, max: 120 }
            }
        })
            .then(screenStream => {
                connectionRef.current.replaceTrack(stream.getVideoTracks()[0], screenStream.getVideoTracks()[0], stream)
                userVideo.current.srcObject = screenStream
                screenStream.getTracks()[0].onended = () => {
                    connectionRef.current.replaceTrack(screenStream.getVideoTracks()[0], stream.getVideoTracks()[0], stream)
                    userVideo.current.srcObject = stream
                }
            })
    }

    const callCandidate = React.useCallback(() => {
        if (channelName in users) {
            callPeer(channelName)
            console.log("Calling " + channelName)
            if (recordingEnable && recorder === null && role.name === 'assessor') {
                onStarted()
            }
        } else {
            setIsConnected(false);
            setCallAccepted(false);
        }
    }, [callPeer, channelName, onStarted, recorder, recordingEnable, role.name, users])

    React.useEffect(() => {
        if (!callAccepted && !callingFriend && !candidateOffline) {
            callCandidate();
        }
    }, [callAccepted, callCandidate, callingFriend, candidateOffline, role.name])

    React.useEffect(() => {
        startStreaming()
        getUserMedia()
    }, [getUserMedia, startStreaming])

    React.useEffect(() => {
        if (!callAccepted && !isConnected) {
            if (channelName in users) {
                callPeer(channelName)
                console.log("Calling Again " + channelName)
            }
        }
    }, [callAccepted, callPeer, channelName, isConnected, users])


    React.useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach((track) => {
                    track.stop();
                    socket.current.disconnect();
                    setCallingFriend(false)
                    setCallAccepted(false)
                    setIsConnected(false)
                    if (connectionRef.current) {
                        connectionRef.current.destroy();
                        socket.current.off('callAccepted');
                    }
                });
            }
        };
    }, [stream]);

    const handle = useFullScreenHandle();

    return (
        <div className='col-span-2' >
            <div className='py-2' style={{ display: renderLanding() }}>
                {receivingCall ? incomingCall : ""}
            </div>
            <div className='grid' >
                <div className="p-2 text-center">
                    <div className='font-bold py-2' >{role.name === 'candidate' ? 'Candidate' : 'Assessor'}</div>
                    <video className="w-full" playsInline muted ref={userVideo} autoPlay />
                    <div style={{ display: renderCall() }}>

                        {callAccepted && isConnected ?
                            <div className='my-2 flex justify-center gird gap-8' >
                                <button onClick={() => shareScreen()} title="Share Screen" className='bg-[#3c4043] rounded-full p-[15px]' >
                                    <span className="text-2xl"  >
                                        <MdScreenShare color='white' />
                                    </span>
                                </button>
                            </div>
                            : ""
                        }
                    </div>
                </div>
                <div className="p-2 text-center" >
                    <div className='font-bold py-2'>{role.name === 'candidate' ? 'Assessor' : 'Candidate'}</div>
                    <div style={{ display: renderCall() }}>
                        <FullScreen handle={handle}>{callAccepted && isConnected ? <video className='w-full h-full' playsInline ref={partnerVideo} autoPlay /> : ""}
                        </FullScreen>
                        {
                            callAccepted && isConnected ?
                                <button onClick={handle.enter} title="Full Screen" className='bg-[#3c4043] rounded-full p-[15px] my-2'>
                                    <span className="text-2xl" >
                                        <AiOutlineFullscreen color='white' />
                                    </span>
                                </button>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default VivaVideoCall
